<template>

</template>
<script>
import {blockUser, setSDK, setUser, startUser} from "@/utils/aplus_cloud";

var that
import Vue from "vue";
import http from '@/utils/httpRequest'
import httpRequestApp from '@/utils/httpRequest-app'
import {twoDomainLogin} from '@/utils/common'
import {initWpk, initWpkDefault} from "@/utils/wpkReporter";
export default {
  data(){
    return{
      path: '',
      appid: '',
      openid: ''
    }
  },
  created() {
    if (window.location.host.indexOf('jjt.sxyc.gov.cn') > -1) {
      // 绍兴越城区
      this.$globalData.appletOrgId = 22766
    } else if (window.location.href.indexOf('yyfbxt.szhz.hangzhou.gov.cn:8068/web/mgop/gov-open/zj/2002387452') > -1 ||
        window.location.href.indexOf('yyfbxt.szhz.hangzhou.gov.cn:8068/web/mgop/gov-open/zj/2002387452/reserved/index.html') > -1){
      this.$globalData.appletOrgId = 12985
    } else if (window.location.href.indexOf('yyfbxt.szhz.hangzhou.gov.cn:8068/web/mgop/gov-open/zj/2002312141') > -1 ||
        window.location.href.indexOf('yyfbxt.szhz.hangzhou.gov.cn:8068/web/mgop/gov-open/zj/2002312141/reserved/index.html') > -1){
      this.$globalData.appletOrgId = 12716
    }else {
      this.$globalData.appletOrgId = 12573
    }
    that = this;
    if (this.$route.query) {
      if (this.$route.query.appletOrgId) {
        this.$globalData.appletOrgId = this.$route.query.appletOrgId
      }
      if (this.$route.query.path) {
        this.path = this.$route.query.path
      }
    }
    that.$globalData.systemErrorMsg = ''
    that.$globalData.systemErrorCode = ''
    that.$globalData.systemEffectiveDate = ''
    initWpk(this.$globalData.appletOrgId)

    //获取免登授权码
    if (this.$route.query.appid && this.$route.query.openid) {
      this.appid = this.$route.query.appid
      this.openid = this.$route.query.openid
      that.$http({
        url: that.$http.adornUrl(`/wxapp/dd/threeParties/getOpenId`),
        method: 'get',
        params: that.$http.adornParams({
          appId: this.appid,
          openId: this.openid
        })
      }).then(({data}) => {
        this.checkLogin(data, {appid: this.appid, openid: this.openid})
      })
    } else {
      let url = window.location.href
      if (url.startsWith('http://wggg.hzuht.com')){
        let groupCode = 0;
        let optionsMe = {
          appKey:'0rAw6ZgOXYDv3Af9tPoP',
          callback: function(res) {
            let text = JSON.stringify(res);
            let obj = JSON.parse(text);
            if (obj.statusCode != 0) {
              alert("授权失败，请重新进入应用")
              console.log("授权失败，请重新进入应用");
              return;
            }
            //进入说明是宿慧办 要先切换地址,由于顶级阿里没部署新版本现在他们本地做
            groupCode = obj.code;
            that.$http({
              url: 'http://sjfb.data.suqian.gov.cn:9100/sgg/api/wxapp/shb/getOpenId',
              method: 'get',
              params: that.$http.adornParams({code: groupCode})
            }).then(({data}) => {
              that.checkLogin(data, {code: groupCode,from:'shb'})
            }).catch(err => {
              console.log(err);
            })
          }
        };
        jme.login.getAuthorizationCode(optionsMe);
      } else {
        dd.getAuthCode({corpId: ""}).then((result) => {
          if (result) {
            that.$http({
              url: that.$http.adornUrl(`/wxapp/dd/getOpenId`),
              method: 'get',
              params: that.$http.adornParams({code: result.code})
            }).then(({data}) => {
              this.checkLogin(data, {code: result.code})
            })
          }
        })
      }
    }
  },
  methods: {
    checkLogin (data, result) {
      if (data.appEffectiveDateEntity) {
        that.$globalData.systemEffectiveDate = data.appEffectiveDateEntity
      }
      let from = result.from || ''
      if (data.code == 0) {
        if(data.homePageVO.orgId === data.homePageVO.street) {
          Vue.prototype.$depth = 4
        }else {
          Vue.prototype.$depth = 5
        }
        Vue.prototype.$globalData.from = from
        Vue.prototype.$globalData.token = data.token
        Vue.prototype.$globalData.topToken = data.token
        Vue.prototype.$globalData.ddUser = data.ddUser
        if (data.homePageVO != null) {
          Vue.prototype.$globalData.topUserId = data.homePageVO.userId
          Vue.prototype.$globalData.domain = decodeURIComponent(data.homePageVO.domain) + '/api'
          Vue.prototype.$globalData.domain2 = decodeURIComponent(data.homePageVO.domain)
          http.changeUrl(data.homePageVO.domain, true);
          httpRequestApp.changeUrl(data.homePageVO.domain, true);

          //浙政钉埋点
          let ua = navigator.userAgent.toLowerCase();
          if (ua.match(/TaurusApp/i) == 'taurusapp') { //浙政钉手机端
            // 流量埋点
            // initWpk(data.ddUser.sappName)
            blockUser()
            // 基础埋点
            setSDK(data.ddUser.aplusAppIds)
            // 用户埋点
            setUser(data.ddUser.accountId, data.ddUser.nickNameCn)
            startUser()
          }

          if (data.homePageVO.domain) {
            twoDomainLogin(data.homePageVO, data.token, result)
            return
          }
        }
        // 如果该方法执行成功则接收返回值
        console.log("login success")
        // alert('/home')
        that.$globalData.userInfo = data.homePageVO
        Vue.prototype.$orgId = data.homePageVO.orgId

        console.log("orgid:" + data.homePageVO.orgId)
        console.log("street:" + data.homePageVO.street)
        if(data.homePageVO.orgId === data.homePageVO.street) {
          // 如果是街道账号就进入街道首页
          Vue.prototype.$globalData.appid = 'wx245d433a2e482e22'
          that.$router.push('/street-home')
        } else if (this.path) {
          that.$router.push(this.path)
        } else {
          that.$router.push('/home')
        }

        // if (this.path) {
        //   that.$router.push(this.path)
        // }

        // console.log("orgid:" + data.homePageVO.orgId)
        // console.log("street:" + data.homePageVO.street)
        // if(data.homePageVO.orgId === data.homePageVO.street) {
        //   // 如果是街道账号就进入街道首页
        //   that.$router.push('/street-home')
        // }else {
        //   that.$router.push('/home')
        // }
      } else {
        console.log("login fail")

        if (data.code == 510 || data.code == 520 || data.code == 530) {
          that.$globalData.systemErrorMsg = data.msg
          that.$globalData.systemErrorCode = data.code
        }

        // 如果请求失败,则表示没有openId , 需要跳转到登录页面
        // Toast.fail(data.msg);
        // that.$router.replace({path: '/login', query: {code: result.code}})
        if (this.path) {
          that.$router.push({path: '/login', query: {code: result.code, path: this.path}})
        } else if (this.appid && this.openid) {
          that.$router.push({path: '/login', query: {appid: this.appid, openid: this.openid}})
        }else if (from && from === 'shb') {
          that.$router.push({path: '/login', query: {code: result.code, from: from}})
        } else {
          that.$router.push({path: '/login', query: {code: result.code}})
        }
      }
    }
  }
}

</script>
<style scoped>

</style>
