export function initWpk(orgId) {
    let sAppName = 'wggg'
    if (orgId === 22766) {
        sAppName = 'sxycjjt'
    } else if (orgId === 12985) {
        sAppName = 'znkybt'
    } else if (orgId === 12716) {
        sAppName = 'bywgt'
    }
    try {
        let config = {
            bid: sAppName + '_zzdpro',
            signkey: '1234567890abcdef',
            gateway: 'https://wpkgate-emas.ding.zj.gov.cn'
        };
        const wpk = new wpkReporter(config);
        wpk.installAll();
        window._wpk = wpk;
    } catch (err) {
        console.error('WpkReporter init fail', err);
    }
}

export function initWpkDefault() {
    try {
        let config = {
            bid: 'wggg_zzdpro',
            signkey: '1234567890abcdef',
            gateway: 'https://wpkgate-emas.ding.zj.gov.cn'
        };
        const wpk = new wpkReporter(config);
        wpk.installAll();
        window._wpk = wpk;
    } catch (err) {
        console.error('WpkReporter init fail', err);
    }

    try {
        let config = {
            bid: 'xhqybt_zzdpro',
            signkey: '1234567890abcdef',
            gateway: 'https://wpkgate-emas.ding.zj.gov.cn'
        };
        const wpk = new wpkReporter(config);
        wpk.installAll();
        window._wpk = wpk;
    } catch (err) {
        console.error('WpkReporter init fail', err);
    }
}
